import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as THREE from 'three';

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showKrishnaVideo, setShowKrishnaVideo] = useState(false); // State to control showing the Krishna video
  const [isSequenceInterrupted, setIsSequenceInterrupted] = useState(false); // State to control sequence interruption
  const [showSphotaAccess, setShowSphotaAccess] = useState(false); // State to control showing the Sphota Access button

  const videoIds = ['video1', 'video2', 'video3', 'video4', 'video5']; // List of videos, stop at video 5

  useEffect(() => {
    if (!isSequenceInterrupted) {
      playVideosInSequence(videoIds);
    }
  }, [isSequenceInterrupted]);

  useEffect(() => {
    if (showKrishnaVideo) {
      const krishnaVideo = document.getElementById('krishnaVideo');
      if (krishnaVideo) {
        krishnaVideo.style.display = 'block';
        krishnaVideo.play();
      }
      setShowSphotaAccess(true); // Show the Sphota Access button when Krishna video plays
    }
  }, [showKrishnaVideo]);

  const handleLoginClick = () => {
    setIsSequenceInterrupted(true);

    // Play the sphota.mp4 video first
    const consolidatedVideo = document.getElementById('consolidatedVideo');
    if (consolidatedVideo) {
      consolidatedVideo.style.display = 'block';
      consolidatedVideo.play();

      consolidatedVideo.onended = () => {
        // After sphota.mp4 ends, play Shri_Krishna.mp4
        setShowKrishnaVideo(true);
        consolidatedVideo.style.display = 'none'; // Hide the sphota video
      };
    }

    const currentVideoElement = document.querySelector('video:not([style*="display: none"])');
    if (currentVideoElement) {
      currentVideoElement.pause();
      currentVideoElement.style.display = 'none';
    }

    const submitBtn = document.getElementById('submitBtn');
    if (submitBtn) {
      submitBtn.style.display = 'none';
    }
  };

  const handleSphotaAccessClick = () => {
    // Navigate to the waitlist page
    navigate('/waitlist');
  };

  const playVideosInSequence = (videoIds) => {
    let currentVideoIndex = 0;

    const playNextVideo = () => {
      if (isSequenceInterrupted) return; // Stop if the sequence is interrupted

      const currentVideoId = videoIds[currentVideoIndex];
      const videoElement = document.getElementById(currentVideoId);

      if (videoElement) {
        videoElement.style.display = 'block';
        videoElement.muted = true;  // Ensure videos are muted
        videoElement.play();

        videoElement.onended = () => {
          videoElement.style.display = 'none';
          currentVideoIndex++;
          if (currentVideoIndex < videoIds.length) {
            playNextVideo();
          } else {
            videoElement.style.display = 'block'; // Keep video 5 visible
          }
        };
      }
    };

    playNextVideo();
  };

  return (
    <div className="home" style={{ overflow: 'hidden', width: '100vw', height: '100vh', position: 'relative' }}>
      <div
        id="3d-content"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
          zIndex: 20,
        }}
      ></div>
      <div id="controlPanel" style={{ position: 'fixed', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 50 }}>
        <button id="submitBtn" style={{ padding: '10px 20px', cursor: 'pointer', background: '#0078D7', border: 'none', color: 'white' }} onClick={handleLoginClick}>
          Sphota
        </button>
        {showSphotaAccess && (
          <button
            id="sphotaAccessBtn"
            style={{ padding: '10px 20px', cursor: 'pointer', background: '#0078D7', border: 'none', color: 'white', marginTop: '10px' }}
            onClick={handleSphotaAccessClick}
          >
            Sphota Access
          </button>
        )}
      </div>

      {/* Adding 5 video elements to be played in sequence */}
      <video id="video1" style={{ width: '100%', height: '100%', position: 'fixed', top: 0, left: 0, zIndex: 5, objectFit: 'cover', display: 'none' }} muted>
        <source src="https://sphota.s3.ap-south-1.amazonaws.com/video1.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <video id="video2" style={{ width: '100%', height: '100%', position: 'fixed', top: 0, left: 0, zIndex: 5, objectFit: 'cover', display: 'none' }} muted>
        <source src="https://sphota.s3.ap-south-1.amazonaws.com/video2.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <video id="video3" style={{ width: '100%', height: '100%', position: 'fixed', top: 0, left: 0, zIndex: 5, objectFit: 'cover', display: 'none' }} muted>
        <source src="https://sphota.s3.ap-south-1.amazonaws.com/video3.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <video id="video4" style={{ width: '100%', height: '100%', position: 'fixed', top: 0, left: 0, zIndex: 5, objectFit: 'cover', display: 'none' }} muted>
        <source src="https://sphota.s3.ap-south-1.amazonaws.com/video4.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <video id="video5" style={{ width: '100%', height: '100%', position: 'fixed', top: 0, left: 0, zIndex: 5, objectFit: 'cover', display: 'none' }} muted>
        <source src="https://sphota.s3.ap-south-1.amazonaws.com/video5.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <video id="consolidatedVideo" style={{ width: '100%', height: '100%', position: 'fixed', top: 0, left: 0, zIndex: 10, objectFit: 'cover', display: 'none' }}>
        <source src="https://sphota.s3.ap-south-1.amazonaws.com/sphota.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <video id="krishnaVideo" style={{ width: '100%', height: '100%', position: 'fixed', top: 0, left: 0, zIndex: 15, objectFit: 'cover', display: 'none' }}>
        <source src="https://sphota.s3.ap-south-1.amazonaws.com/Shri_Krishna.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Copyright Notice */}
      <div style={{ position: 'fixed', bottom: '5px', right: '5px', zIndex: 50, fontSize: '12px', color: '#000' }}>
        © 2024 Shubham Bhardwaj. All rights reserved.
      </div>
    </div>
  );
};

export default Home;
