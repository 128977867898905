import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Waitlist = () => {
  const [email, setEmail] = useState('');
  const [skill1, setSkill1] = useState('');
  const [skill2, setSkill2] = useState('');
  const [skill3, setSkill3] = useState('');
  const [message, setMessage] = useState('');  // State to hold the success message
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!email.trim()) {
      alert('Please enter your email.');
      return;
    }

    try {
      const response = await fetch('/api/waitlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, skill1, skill2, skill3 }),
      });

      const data = await response.json(); // Parse the JSON response

      if (response.ok && data.success) {  // Check for both HTTP status and success flag
        setMessage('Thanks for expressing your interest.');  // Set the success message

        // Redirect after 3 seconds
        setTimeout(() => {
          navigate('/');
        }, 3000);

      } else {
        alert(data.message || 'Something went wrong, please try again!');
      }
    } catch (error) {
      console.error('Attempt failed:', error);
      alert('An error occurred, please try again!');
    }
  };

  return (
    <div style={styles.container}>
      {message ? (  // Conditional rendering for the success message
        <div style={styles.messageBox}>
          <p style={styles.successMessage}>{message}</p>
        </div>
      ) : (
        <div id="controlPanel" style={styles.controlPanel}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={styles.input}
          />
          <p style={styles.message}>Your Top 3 Distinct Skills from Any Domain (Optional)</p>
          <input
            type="text"
            placeholder="Skill 1"
            value={skill1}
            onChange={(e) => setSkill1(e.target.value)}
            style={styles.input}
          />
          <input
            type="text"
            placeholder="Skill 2"
            value={skill2}
            onChange={(e) => setSkill2(e.target.value)}
            style={styles.input}
          />
          <input
            type="text"
            placeholder="Skill 3"
            value={skill3}
            onChange={(e) => setSkill3(e.target.value)}
            style={styles.input}
          />
          <button onClick={handleLogin} style={styles.button}>Join Waitlist</button>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    overflow: 'hidden',
    backgroundImage: `url(${process.env.PUBLIC_URL + '/Login.png'})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
  },
  controlPanel: {
    width: '350px',
    padding: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    borderRadius: '8px',
    border: '2px solid #444',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 20,
  },
  messageBox: {
    padding: '20px',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    borderRadius: '8px',
    textAlign: 'center',
  },
  successMessage: {
    color: 'white',
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#f0f0f0',
  },
  button: {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    border: 'none',
    borderRadius: '4px',
    background: '#555555',
    color: 'white',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
};

export default Waitlist;
